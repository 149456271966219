<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-grid>
        <ion-row class="align-center" id="row">
          <ion-col size="9" class="d-flex">
            <ion-buttons class="mr-2" @click="$emit('close-modal')">
              <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
            </ion-buttons>
            <ion-label class="fs-4 fw-bold">{{ title }}</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonIcon, IonToolbar, IonGrid, IonRow, IonLabel, IonCol, IonButtons, IonHeader } from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalHeader',
  components: {
    IonIcon,
    IonToolbar,
    IonGrid,
    IonRow,
    IonLabel,
    IonCol,
    IonButtons,
    IonHeader
  },
  props: {
    title: {
      required: true
    }
  },
  emits: ['close-modal'],
  setup() {
    return {
      arrowBackOutline
    };
  }
});
</script>

<style scoped>
.custom-toolbar {
  --border-width: 0px !important;
}
.custom-header {
  height: 56px;
}
.custom-header.header-md::after {
  background-image: none;
}
.custom-button::part(native) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
#row {
  height: 50px;
}
</style>
