<template>
  <!-- map -->
  <div>
    <ion-grid class="ion-no-padding">
      <ion-list mode="ios" lines="none">
        <ion-list class="mt-1" v-if="isBranch && customerDetails?.persons?.length > 0">
          <ion-item>
            <ion-label class="text-title">{{ $t('newcustomer.same_as_parent_company') }}</ion-label>
            <ion-checkbox
              v-model="sameAsParent"
              :value="sameAsParent"
              @update:modelValue="handleOpenModalSameasParent"
              slot="start"
            >
            </ion-checkbox>
          </ion-item>
        </ion-list>
        <!-- Start Fist Name -->
        <div>
          <ion-item
            :class="[
              form.firstName.isError
                ? 'ion-item-danger'
                : !form.firstName.isError && form.firstName.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.firstName.isError
                  ? 'danger'
                  : !form.firstName.isError && form.firstName.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('first_name') }}</ion-label
            >
            <ion-input
              :disabled="sameAsParent"
              :placeholder="$t('key_contact.enter_first_name')"
              ref="companyName"
              v-model.trim="form.firstName.value"
              @ionInput="handleValidateForm('firstName')"
              enterkeyhint="done"
              @keyup.enter="onCloseKeyboard"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.firstName.isError">
            <div class="py-1 fs-12px text-danger">
              {{
                $t(
                  `${
                    form.firstName.value.length === 0
                      ? 'first_name_cannot_be_empty'
                      : 2 > form.firstName.value.length
                      ? 'first_name_must_be_at_least_2_characters'
                      : ''
                  }`
                )
              }}
            </div>
          </ion-text>
        </div>
        <!-- End Fist Name -->

        <!-- Start Last Name -->
        <div>
          <ion-item :class="[form.lastName.value ? 'ion-item-primary' : '']">
            <ion-label class="text-title" :color="form.lastName.value ? 'primary' : ''" position="stacked">{{
              $t('last_name')
            }}</ion-label>
            <ion-input
              :disabled="sameAsParent"
              :placeholder="$t('key_contact.enter_last_name')"
              ref="companyName"
              v-model.trim="form.lastName.value"
              enterkeyhint="done"
              @keyup.enter="onCloseKeyboard"
            ></ion-input>
          </ion-item>
        </div>
        <!-- End Last Name -->

        <!-- Start Phone -->
        <div>
          <div
            class="mt-3"
            :style="
              form.phone.isError
                ? 'border-bottom: 1px solid red'
                : !form.phone.isError && form.phone.value
                ? 'border-bottom: 1px solid #04565A'
                : 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
            "
          >
            <ion-label
              class="text-title"
              :style="sameAsParent ? `opacity:0.25` : ''"
              :color="
                form.phone.isError
                  ? 'danger'
                  : !form.phone.isError && form.phone.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('phone') }}</ion-label
            >
            <div class="d-flex align-center">
              <vue-tel-input
                v-model.trim="form.phone.value"
                mode="national"
                enabledCountryCode
                enabledFlags
                validCharactersOnly
                pattern="[0-9]*$"
                :styleClasses="!sameAsParent ? 'no-border no-box-shadow' : 'no-border no-box-shadow provip'"
                ref="phone-field"
                inputId="phone-field"
                disabledFetchingCountry
                :onlyCountries="getPreferedCountryCode()"
                :autoDefaultCountry="false"
                :defaultCountry="originCode"
                :autoFormat="false"
                :disabled="sameAsParent"
                @keypress="blockDecimalInput"
                @on-input="onlyNumber"
                :inputOptions="{
                  type: 'tel',
                  maxlength: 16,
                  placeholder: $t('mobile_number')
                }"
                @country-changed="countryChange"
                enterkeyhint="done"
                @keyup.enter="onCloseKeyboard"
              />
            </div>
          </div>
          <ion-text v-if="form.phone.isError">
            <div class="py-1 fs-12px text-danger">
              {{ $t('phone_number_cannot_be_empty') }}
            </div>
          </ion-text>
        </div>
        <!-- End Phone -->

        <!-- Start Email -->
        <div>
          <ion-item
            :class="[
              form.email.isError
                ? 'ion-item-danger'
                : !form.email.isError && form.email.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.email.isError
                  ? 'danger'
                  : !form.email.isError && form.email.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('email') + ' ' + $t('Optional') }}</ion-label
            >
            <ion-input
              :disabled="sameAsParent"
              placeholder="hello@example.com"
              ref="companyName"
              v-model.trim="form.email.value"
              @ionInput="handleValidateForm('email')"
              enterkeyhint="done"
              @keyup.enter="onCloseKeyboard"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.email.isError">
            <div class="py-1 fs-12px text-danger">
              {{ $t('this_field_must_be_a_valid_email') }}
            </div>
          </ion-text>
        </div>
        <!-- End Email -->
        <!-- StartCustomerProfile -->
        <div>
          <ion-item
            :class="[
              form.language.isError
                ? 'ion-item-danger'
                : !form.language.isError && form.language.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="ion-mb text-title"
              :color="
                form.language.isError
                  ? 'danger'
                  : !form.language.isError && form.language.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('language_preference') }}</ion-label
            >
            <ion-select
              :disabled="sameAsParent"
              mode="ios"
              name="profile"
              v-model="form.language.value"
              :okText="$t('OK')"
              :cancelText="$t('cancel')"
            >
              <ion-select-option v-for="(data, index) in language" :key="index" :value="data.key">
                {{ data.value }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-text>
            <div v-if="form.language.isError" class="py-1 fs-12px text-danger">
              {{ $t('customerPage.profile_cannot_be_empty') }}
            </div>
          </ion-text>
        </div>

        <!-- start btn -->
        <div>
          <ion-button mode="md" @click="backStep" fill="outline" class="mt-5 fw-500 ion-float-left">{{
            $t('back')
          }}</ion-button>
          <ion-button mode="md" @click="handleAddKeyContactInformation" class="mt-5 fw-500 ion-float-right">{{
            $t('next')
          }}</ion-button>
        </div>
        <!-- end btn -->
      </ion-list>
    </ion-grid>
    <ion-modal mode="md" :is-open="isOpenRef" css-class="my-custom-class" @didDismiss="setOpen(false)">
      <ModalSearchKeyContact
        @closeModal="setOpen(false)"
        @handleBackCustomerPage="handleBackCustomerPage"
        :dataCheckDuplicatePerson="dataCheckDuplicatePerson"
        :phone="form.phone.value"
        :countryCode="countryCode"
      />
    </ion-modal>
    <ion-modal
      mode="md"
      :is-open="isOpenModalSameKeyContact"
      css-class="modal-add custom-modal"
      @didDismiss="handleCloseModal"
    >
      <ModalSameKeyContact :persons="customerDetails.persons" @sendKeyContact="handleSendKeyContact" />
    </ion-modal>
    <ion-loading
      mode="ios"
      :is-open="isOpenRefLoading"
      cssClass="my-custom-class"
      :message="$t('please_wait')"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import {
  findObjectByKey,
  getDefaultCountryCode,
  getPreferedCountryCode
} from '@/modules/sale/services/libs/helper';
import { defineComponent, ref } from 'vue';

import { apolloClient } from '@/main';
import { saleCheckDuplicatePerson } from '@/modules/sale/services/graphql';
import { getPositions, getRegexEmail } from '@/modules/sale/services/libs/helper';
import { picDefaultLanguages } from '@/services/shared/helper/countries';
import { Keyboard } from '@capacitor/keyboard';
import { alertController } from '@ionic/vue';
import { searchOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { VueTelInput } from 'vue-tel-input';
import '../styles/VueTelInput.scss';
import ModalSameKeyContact from './ModalSameKeyContact.vue';
import ModalSearchKeyContact from './ModalSearchKeyContact.vue';
export default defineComponent({
  name: 'AddressForm',
  components: {
    VueTelInput,
    ModalSearchKeyContact,
    ModalSameKeyContact
  },
  inject: ['$storage'],
  props: [
    'keyContactInformation',
    'phoneProps',
    'countryCodeProps',
    'countryId',
    'isBranch',
    'customerDetails',
    'sameAsParent_keyConTact',
    'originCode'
  ],
  data() {
    return {
      isOpenRef: false,
      isOpenRefLoading: false,
      sameAsHead: false,
      positions: getPositions(),
      dataCheckDuplicatePerson: [],
      countryCode: '',
      form: {
        firstName: {
          isError: false,
          value: ''
        },
        lastName: {
          value: ''
        },
        phone: {
          isError: false,
          value: ''
        },
        position: {
          isError: false,
          value: 'Key Contact'
        },
        email: {
          isError: false,
          value: ''
        },
        language: {
          isError: false,
          value: 'en-us'
        }
      },
      language: picDefaultLanguages
    };
  },
  setup() {
    const user = ref(null);
    const sameAsParent = ref(false);
    const isOpenModalSameKeyContact = ref(false);
    const setOpenModalSameKeyContact = (state) => (isOpenModalSameKeyContact.value = state);
    const isSameAsParent = ref(false);
    const isFirstMouted = ref(true); //check if user back and is same as parent ===true it dont show modal
    const onCloseKeyboard = () => {
      Keyboard.hide();
    };
    return {
      user,
      sameAsParent,
      isOpenModalSameKeyContact,
      setOpenModalSameKeyContact,
      searchOutline,
      findObjectByKey,
      isSameAsParent,
      isFirstMouted,
      picDefaultLanguages,
      getPreferedCountryCode,
      onCloseKeyboard
    };
  },

  watch: {
    'form.phone.value'() {
      this.handleValidateForm('phone');
      if (6 < this.form.phone.value?.length < 12) {
        this.handleSearchPhoneNumber();
      }
    },
    'form.position.value'() {
      this.handleValidateForm('position');
    },
    countryCode() {
      this.countryCode == 86
        ? (this.form.language.value = 'zh-CN')
        : this.countryCode == 60
        ? (this.form.language.value = 'ms-MY')
        : this.countryCode == 62
        ? (this.form.language.value = 'id-ID')
        : (this.form.language.value = 'en-US');
    }
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.handleLoadOldDataWhenUserBack();
  },
  methods: {
    //
    handleSendKeyContact(event) {
      const data = event;
      this.form.firstName.value = data.first_name;
      this.form.lastName.value = data.last_name;
      this.form.email.value = data.email;
      this.form.language.value = data.language || 'en-US';
      //reset validate error
      this.form.firstName.isError = false;
      this.form.phone.isError = false;
      this.form.email.length ? (this.form.email.isError = false) : '';
      this.handleGetPhone(data.phone);
      // this.handleCloseModal();
      this.isSameAsParent = true;
      this.setOpenModalSameKeyContact(false);
    },
    handleGetPhone(payload) {
      let data = payload;
      const cutPhone =
        data.indexOf(' ') > -1 ? data.split(' ')[1] : data.length > 8 ? data.substring(2, data.length) : data;
      this.$nextTick(() => {
        this.splitPhoneAndCountryCode(data, cutPhone);
      });
    },
    splitPhoneAndCountryCode(fullPhoneNumber, phone) {
      this.countryCode =
        fullPhoneNumber.indexOf(' ') > -1
          ? fullPhoneNumber.split(' ')[0]
          : fullPhoneNumber.length > 8
          ? fullPhoneNumber.substring(0, 2)
          : getDefaultCountryCode();
      const country = this.findObjectByKey(
        this.$refs['phone-field'].allCountries, // List country
        'dialCode', // Key to find
        this.countryCode // value to find
      );

      this.defaultCountry = country ? country.iso2 : null;
      this.$refs['phone-field'].choose(this.defaultCountry);
      this.form.phone.value = phone;
    },
    handleOpenModalSameasParent() {
      if (this.sameAsParent && this.isFirstMouted) {
        this.setOpenModalSameKeyContact(true);
      }
      this.isFirstMouted = true;
    },
    handleCloseModal() {
      if (!this.isSameAsParent) {
        this.sameAsParent = false;
      }
      this.setOpenModalSameKeyContact(false);
      this.isSameAsParent = false;
    },
    //
    handleLoadOldDataWhenUserBack() {
      if (this.keyContactInformation.length !== 0) {
        const data = this.keyContactInformation;
        this.form.firstName.value = data.first_name;
        this.form.lastName.value = data.last_name;
        this.form.phone.value = this.phoneProps;
        this.form.position.value = data.position;
        this.form.email.value = data.email;
        this.countryCode = this.countryCodeProps;
        this.isFirstMouted = false;
        this.sameAsParent = this.sameAsParent_keyConTact;
      }
    },
    async handleBackCustomerPage() {
      await this.setOpen(false);
      await this.$router.push('/sale/customers');
    },

    setOpen(params) {
      this.isOpenRef = params;
    },
    setOpenLoading(params) {
      this.isOpenRefLoading = params;
    },
    countryChange(val) {
      this.defaultCountry = val.iso2;
      this.countryCode = val.dialCode || getDefaultCountryCode();
    },
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.form.phone.value = input;
    },

    blockDecimalInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    validateForm(params) {
      if (params === 'firstName') {
        const lengthFistName = this.form.firstName.value.length;
        lengthFistName === 0 || lengthFistName < 2
          ? (this.form.firstName.isError = true)
          : (this.form.firstName.isError = false);
      } else if (params === 'email') {
        this.form.email.value && !getRegexEmail().test(this.form.email.value)
          ? (this.form.email.isError = true)
          : (this.form.email.isError = false);
      } else {
        this.form.phone.value.length === 0
          ? (this.form.phone.isError = true)
          : (this.form.phone.isError = false);
      }
    },

    handleValidateForm: debounce(function (params) {
      this.validateForm(params);
    }, 500),
    handleSearchPhoneNumber: debounce(async function () {
      await this.setOpenLoading(true);
      try {
        const { data } = await apolloClient.query({
          query: saleCheckDuplicatePerson,
          variables: {
            tenantId: this.user?.tenant?.id,
            data: {
              country_code: `${this.countryCode}`,
              phone: `${this.form.phone.value}`
            }
          }
        });

        await this.setOpenLoading(false);
        if (data.saleCheckDuplicatePerson.length !== 0) {
          this.dataCheckDuplicatePerson = data.saleCheckDuplicatePerson;
          this.$emit('handleCheckDuplicatePerson', true);
          this.setOpen(true);
        } else {
          this.$emit('handleCheckDuplicatePerson', false);
        }
      } catch (error) {
        await this.setOpenLoading(false);
        this.presentAlert(error);
      }
    }, 1500),
    async handleAddKeyContactInformation() {
      this.isOpenModal = false;
      const data = ['firstName', 'email', 'phone'];
      for (let value of data) {
        this.validateForm(value);
      }
      let isAddKeyContact = true;
      for (let i in this.form) {
        if (this.form[i].isError === true) {
          isAddKeyContact = false;
          break;
        }
      }
      if (isAddKeyContact) {
        const item = {
          user_id: this.user.id,
          country_code: `${this.countryCode}`,
          email: this.form.email.value,
          phone: `${this.form.phone.value}`,
          first_name: this.form.firstName.value,
          last_name: this.form.lastName.value,
          position: this.form.position.value,
          language: this.form.language.value
        };
        this.$emit(
          'handleDataKeyContactInformation',
          item,
          this.countryCode,
          this.form.phone.value,
          this.sameAsParent
        );
      }
    },
    async backStep() {
      const item = {
        user_id: this.user.id,
        country_code: `${this.countryCode}`,
        email: this.form.email.value,
        phone: `${this.form.phone.value}`,
        first_name: this.form.firstName.value,
        last_name: this.form.lastName.value,
        position: this.form.position.value,
        language: this.form.language.value
      };

      this.$emit('backStepOne', item, this.countryCode, this.form.phone.value, this.sameAsParent);
    },
    //show message error
    async presentAlert(params) {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: params,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    }
  }
});
</script>
<style src="../styles/KeyContactInformation.scss" lang="scss" scoped></style>
