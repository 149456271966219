<template>
  <ion-page>
    <div class="header">
      <ion-button color="light" fill="clear" class="ml-auto btn-close" @click.stop="$emit('close-modal')">
        <ion-icon slot="icon-only" :icon="closeOutline" />
      </ion-button>
    </div>
    <ion-content class="ion-padding custom-content">
      <div class="h-92">
        <ion-img :src="src" />
      </div>
    </ion-content>
    <ion-footer>
      <!-- button save -->
      <ion-button expand="block" color="success" class="mt-auto" @click="$emit('confirm-image', src)">
        {{ $t('confirm') }}
      </ion-button>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonContent, IonButton, IonIcon, IonImg } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageConfirm',
  components: {
    IonContent,
    IonButton,
    IonIcon,
    IonImg
  },
  props: {
    src: {
      required: true
    }
  },
  mounted() {
    this.listImage = this.customerImage;
  },
  emits: ['close-modal', 'confirm-image'],
  setup() {
    return {
      closeOutline
    };
  }
});
</script>

<style scoped>
.custom-content::part(scroll) {
  padding-top: 0px;
  background: black;
  padding: 24px;
}
.header {
  display: flex;
  height: 56px;
}
.h-92 {
  height: 92%;
}
.btn-close {
  margin-top: 10px;
  font-size: 18px;
}
</style>

<style>
.modal-image.custom-modal .modal-wrapper {
  background: black;
  --background: black;
}
.modal-image.custom-modal .modal-shadow {
  display: none;
}
</style>
