<template>
  <td-image :image="image" :imagePath="IMAGE_PATH.CUSTOMER"></td-image>
</template>

<script>
import { IMAGE_PATH } from '@/modules/sale/constants/index';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompanyImages',
  setup() {
    return {
      IMAGE_PATH
    };
  },
  data() {
    return {
      isErrorImg: false
    };
  },
  props: ['image']
});
</script>

<style></style>
