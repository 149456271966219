<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-title>{{ $t('key_contact_label') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="mx-4" v-for="(data, index) in persons" :key="index">
        <ion-item @click="handleSendKeyContact(data)">
          <ion-avatar slot="start">
            <div>{{ data.first_name?.[0] || data.last_name?.[0] || '' }}</div>
          </ion-avatar>
          <ion-label>
            <h2>{{ data.first_name }} {{ data.last_name }}</h2>
            <h3>{{ data.position }}</h3>
            <p>{{ data.phone ? '+' + handleGetMobilePhone(data.phone) : '' }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer mode="ios" class="footer ion-no-border">
      <ion-toolbar> </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { getDefaultCountryCode } from '@/modules/b2b/services/libs/helper';
export default {
  props: ['persons'],
  methods: {
    handleGetMobilePhone(params) {
      const phone =
        params.indexOf(' ') > -1
          ? params.split(' ')[1]
          : params.length > 8
          ? params.substring(2, params.length)
          : params;
      const countryCode =
        params.indexOf(' ') > -1
          ? params.split(' ')[0]
          : params.length > 8
          ? params.substring(0, 2)
          : getDefaultCountryCode();
      const phoneNumber = countryCode + ' ' + phone;
      return phoneNumber;
    },
    handleSendKeyContact(e) {
      this.$emit('sendKeyContact', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  height: 10px !important;
}
ion-avatar {
  background: #04565a !important;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
</style>
