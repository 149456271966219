<template>
  <ion-page>
    <modal-header
      :title="(listImage.length ? $t('update') : $t('add')) + ' ' + $t('customerPage.images')"
      @close-modal="$emit('close-modal')"
    />
    <ion-content class="ion-padding custom-content">
      <div class="h-93">
        <!-- list image -->
        <ion-row class="px-5 py-3">
          <ion-col size="4" class="position-relative px-3 mb-4" v-for="image in listImage" :key="image.id">
            <image-company :image="image.image" />
            <ion-button
              color="danger"
              fill="clear"
              size="small"
              class="btn-delete"
              @click.stop="deleteImage(image.id, false)"
            >
              <ion-icon slot="icon-only" :icon="closeOutline" />
            </ion-button>
          </ion-col>

          <ion-col
            size="4"
            class="position-relative px-3"
            v-for="(image, index) in listAddImage"
            :key="index"
          >
            <ion-img :src="image.image" />
            <ion-button
              color="danger"
              fill="clear"
              size="small"
              class="btn-delete"
              @click.stop="deleteImage(index, true)"
            >
              <ion-icon slot="icon-only" :icon="closeOutline" />
            </ion-button>
          </ion-col>
        </ion-row>
        <!-- upload image -->
        <ion-input
          placeholder=""
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onPhotoChanged"
          ref="photoInput"
          v-model="photoInput"
          class="d-none"
        >
        </ion-input>
        <ion-button class="w-90 company-button ml-4 font-size-12" color="primary" @click="triggerUpload">
          <ion-icon slot="icon-only" class="fs-3" :icon="addOutline" />
          {{ $t('add') }} {{ $t('customerPage.image') }}
        </ion-button>
      </div>
    </ion-content>
    <ion-footer>
      <!-- button save -->
      <ion-button expand="block" color="primary" class="mt-auto" @click="saveImage">
        {{ $t('save') }}
      </ion-button>
    </ion-footer>
  </ion-page>
  <!-- modal image -->
  <ion-modal
    :is-open="isOpenModalImageConfirmRef"
    css-class="modal-image custom-modal"
    @didDismiss="setOpenModalImageConfirm(false)"
  >
    <modal-image-confirm @close-modal="closeImageConfirm" @confirm-image="confirmImage" :src="srcAddImage">
    </modal-image-confirm>
  </ion-modal>
</template>

<script>
import { addOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { fileToBase64 } from '@/modules/sale/services/libs/image';
import ModalHeader from '@/modules/sale/views/customer/CustomerDetail/components/ModalHeader.vue';
import ImageCompany from './ImageCompany.vue';
import ModalImageConfirm from './ModalConfirmImage.vue';

export default defineComponent({
  name: 'ImageModal',
  components: {
    // user defined component
    ModalHeader,
    ImageCompany,
    ModalImageConfirm
  },
  data() {
    return {
      photoInput: null,
      srcAddImage: '',
      listImage: [],
      listAddImage: [],
      listDeleteImage: []
    };
  },
  props: {
    customerImage: {
      required: true
    },
    customerId: {
      required: true
    },
    listAddImageProps: {
      required: true
    },
    listDeleteImageProps: {
      required: true
    }
  },

  mounted() {
    if (this.listAddImageProps || this.listDeleteImageProps) {
      this.listAddImage = this.listAddImageProps;
      this.listDeleteImage = this.listDeleteImageProps;
      this.$nextTick(() => {
        this.listAddImage = this.listAddImageProps;
        this.listDeleteImage = this.listDeleteImageProps;
      });
    }
    this.listImage = this.customerImage;
  },
  emits: ['close-modal', 'save-image'],
  setup() {
    // modal image confirm
    const isOpenModalImageConfirmRef = ref(false);
    const setOpenModalImageConfirm = (state) => (isOpenModalImageConfirmRef.value = state);
    return {
      addOutline,
      closeOutline,
      // modal image confirm
      isOpenModalImageConfirmRef,
      setOpenModalImageConfirm
    };
  },
  methods: {
    async triggerUpload() {
      const ionInputEle = this.$refs.photoInput.$el;
      const inputEle = await ionInputEle.getInputElement();
      inputEle.click();
    },
    async onPhotoChanged(event) {
      const files = event.target.querySelector('input').files;
      if (!files.length) {
        return;
      }

      if (!files[0].type.includes('image')) {
        return;
      }

      this.srcAddImage = await fileToBase64(files[0]);
      this.setOpenModalImageConfirm(true);
    },
    deleteImage(imageIdx, isNew) {
      if (isNew) {
        this.listAddImage = this.listAddImage.filter((item, index) => index !== imageIdx);
      } else {
        this.listDeleteImage.push(imageIdx);
        this.listImage = this.listImage.filter((item) => item.id !== imageIdx);
      }
    },
    closeImageConfirm() {
      this.photoInput = null;
      this.setOpenModalImageConfirm(false);
    },
    confirmImage(image) {
      this.photoInput = null;
      const newImage = {
        customer_id: this.customerId,
        active: true,
        image
      };
      this.listAddImage.push(newImage);
      this.setOpenModalImageConfirm(false);
    },
    saveImage() {
      this.$emit('save-image', this.listAddImage, this.listDeleteImage);
    }
  }
});
</script>

<style scoped>
.custom-content::part(scroll) {
  padding-top: 0px;
}
.btn-delete {
  position: absolute;
  top: -20px;
  right: -25px;
}
.h-93 {
  height: 93%;
}
</style>
